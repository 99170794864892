import React, {Component} from 'react'
import {base, appDatabasePrimaryFunctions} from '../../base';
import { Redirect } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { validateEmail, getURLParameter } from '../utils/HelpfulFunction';
import { confirmAlert } from '../utils/react-confirm-alert';
import appBackgroundImage from '../../styles/images/background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/Home.css';
import '../../styles/css/main.css';

class Login extends Component {
    constructor(props) {
        super(props);
        let userEmail;
        try {
          userEmail = sessionStorage.getItem('userEmail') || false;
        } catch (e) {
          userEmail = false
        }
        this.state = {
          redirect: false,
          tenantVariables: {},
          inputtedEmail: '',
          inputtedName: '',
          inputtedZipCode: '',
          inputtedPhoneNumber: '',
          inputtedFullAddress: '',
          userEmail: userEmail,
          modalRules: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    componentDidMount() {
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.props.loadPage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const stringConstants = this.props.stringConstants || {};
      if(!navigator.cookieEnabled){
        alert(stringConstants.NOCOOKIESTEXT);
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantRulesRef);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    toggleSupport(){
    this.setState({
      modalSupport: !this.state.modalSupport,
    });
  }

    sanitizeEmail(emailAddress){
      //get part of email before @ sign, this has different rules then text after the @
      const firstPartOfEmail = emailAddress.split('@')[0];
      //get rest of email to recombine later
      const secondPartOfEmail = emailAddress.split('@')[1];
      //Removes periods because on some mail clients they can be moved around and we will treat them like a separate email
      const removedPeriodsFirstPartOfEmail = firstPartOfEmail.replace(/\./g, "");
      //Check if there is a plus in the email
      const splitPluses = removedPeriodsFirstPartOfEmail.split('+');
      let finalFirstPart;
      if (splitPluses.length > 1)
      {
        //if there is a plus cut all the nonsense after the plus, this is important because some email clients allow you to put whatever you want after the plus
        finalFirstPart = splitPluses[0];
      }
      else
      {
        //if no plus continue with periods removed from first part of email
        finalFirstPart = removedPeriodsFirstPartOfEmail;
      }
      //recombine email to make the sanitized version
      const sanitizedEmail = finalFirstPart + "@" + secondPartOfEmail;
      //change to upper so people can sneak by case on me
      return sanitizedEmail.toLowerCase();
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    handleDateChange(event) {
    let value = event.target.value;
    if(event.target.name === "month"){
      if(value > 12){
        value = "12"
      }
    } else if(event.target.name === "day"){
      if(value > 31){
        value = "31"
      }
    } else if(event.target.name === "year"){
      if(value > new Date().getFullYear()){
        value = new Date().getFullYear().toString()
      }
    }
    this.setState({[event.target.name]: value});
  }

    isValidDate(s) {
      const bits = s.split('-');
      const d = new Date(bits[0], bits[1] - 1, bits[2]);
      return d && (d.getMonth() + 1) === parseInt(bits[1]);
    }

    handleSubmit(event){
      event.preventDefault();
      const stringConstants = this.props.stringConstants || {};
      const inputtedEmail = this.state.inputtedEmail.trim().toLowerCase();
      const tenantVariables = this.props.variables;
      const inputtedName = this.state.inputtedName.trim();
      let htmlParameterEmail = getURLParameter("email");
      if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
        htmlParameterEmail = null
      }
      const year = this.state.year;
      let month = this.state.month;
      let day = this.state.day;
      let inputtedBirthday = sessionStorage.getItem('birthday') || "";
      const inputtedZipCode = this.state.inputtedZipCode.trim();
      const inputtedFullAddress = this.state.inputtedFullAddress.trim();
      const inputtedPhoneNumber = this.state.inputtedPhoneNumber.trim();
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      const agree_to_promotionRef = this.refs.agree_to_promotion.checked;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const noCollectingEmails = tenantVariables.doNotCollectEmail || isMlbApp;

      if(inputtedEmail === "" && !htmlParameterEmail && !noCollectingEmails){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLEMAILERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(!noCollectingEmails && !validateEmail(inputtedEmail)){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.UNABLETOREADEMAILTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(!noCollectingEmails && (tenantVariables.allowList || tenantVariables.blockList)){
        const lowerCaseEmail = inputtedEmail.toLowerCase();
        const emailDomain = lowerCaseEmail.split('@')[1];
        let allowListArray = [];
        let blockListArray = [];
        if(tenantVariables.allowList){
          allowListArray = tenantVariables.allowList.split(" ");
        }
        if(tenantVariables.blockList){
          blockListArray = tenantVariables.blockList.split(" ");
        }
        if(tenantVariables.allowList && allowListArray.indexOf(lowerCaseEmail) === -1 && allowListArray.indexOf(emailDomain) === -1){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.EMAILEERRORHEADER,
            message: stringConstants.EMAILNOTALLOWEDERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        } else if(tenantVariables.allowList && (allowListArray.indexOf(lowerCaseEmail) !== -1 || allowListArray.indexOf(emailDomain) === -1)) {
          console.log("Allow override")
        } else if(tenantVariables.blockList && (blockListArray.indexOf(lowerCaseEmail) !== -1 || blockListArray.indexOf(emailDomain) !== -1)){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.EMAILEERRORHEADER,
            message: stringConstants.EMAILNOTALLOWEDERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
      }
      const allowedAge = tenantVariables.allowedAge || 21;
      if(tenantVariables.collectBirthday && tenantVariables.formBirthday && (!year || !month || !day)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLBIRTHDAYERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectBirthday && tenantVariables.formBirthday){
        if(year.length < 4){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.BIRTHDAYYEARBADFORMAT,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
        if(month && month.length < 2){
          month = "0" + month
        }
        if(day && day.length < 2){
          day = "0" + day
        }
        inputtedBirthday = year + "-" + month + "-" + day;
        if(isNaN(new Date(inputtedBirthday).getTime()) || !this.isValidDate(inputtedBirthday)){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.INVALIDDATEERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
        const age = ~~((new Date().getTime() - +new Date(inputtedBirthday)) / (31557600000))
        if (age < allowedAge) {
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.INPUTERRORTITLE,
            message: stringConstants.NOTOLDENOUGHERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
      }
      if(tenantVariables.collectName && inputtedName === ""){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLNAMEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectName && (inputtedName === "" || inputtedName.split(' ').length <= 1 || inputtedName.length > 35)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.INPUTERRORTITLE,
          message: stringConstants.NOLASTNAMEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYPHONENUMBERTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectPhoneNumber && !/^\d+$/.test(inputtedPhoneNumber)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOTAPHONENUMBERTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber.length !== 10){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOTAPHONENUMBERTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      if(tenantVariables.collectZipCode && inputtedZipCode === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLZIPCODEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectZipCode && !tenantVariables.doNotVerifyAmericanZipCode && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(inputtedZipCode)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOTVALIDUSZIP,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectFullAddress && inputtedFullAddress === ""){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: 'Please enter a full address.',
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(!tenantVariables.noMandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOAGREERULESANDREGS,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      // Play with ' + this.state.inputedEmail + '?'
      if(noCollectingEmails){
        this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress)
        return
      }
      if(!htmlParameterEmail) {
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.CONFIRMEMAILTEXT,
          message: inputtedEmail,
          buttons: [
            {
              label: stringConstants.NODISMISSBUTTONTEXT,
            },
            {
              label: stringConstants.YESCONFIRMBUTTONTEXT,
              onClick: async () => {
                this.setState({
                  loading:true
                })
                this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress)
              }
            }
          ]
        })
      } else {
        this.props.setPassed(true);
        this.createUser(htmlParameterEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress);
      }
    }

    createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress){
      let base64EncodedEmail = appDatabasePrimaryFunctions.ref().push().key;
      let userId = getURLParameter("userid");
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      let sanitizedEmail = "";
      if(inputtedEmail){
        try {
          sanitizedEmail = this.sanitizeEmail(inputtedEmail);
        } catch (e) {
          console.log(e)
        }
        base64EncodedEmail = btoa(inputtedEmail);
      } else if(userId){
        base64EncodedEmail = userId;
      } else if(!userId && isMlbApp) {
        confirmAlert({
          variables: this.props.variables,
          title: "Login Error",
          message: "Something went wrong please make sure you are logging in via the MLB Ballpark app to play. If you are on the Ballpark app and are seeing this message please close the app and try again.",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return
      }
      const userObject = {};
      userObject['lastSignIn'] = new Date().getTime();
      userObject['email'] = inputtedEmail;
      userObject['sanitizedEmail'] = sanitizedEmail;
      userObject['uid'] = base64EncodedEmail;
      if(inputtedZipCode){
        userObject['zipCode'] = inputtedZipCode;
      }
      if(inputtedName){
        userObject['name'] = inputtedName;
      }
      if(inputtedFullAddress){
        userObject['address'] = inputtedFullAddress;
      }
      userObject['optIn'] = agree_to_promotionRef;
      if(inputtedBirthday){
        userObject['birthday'] = inputtedBirthday;
      }
      if(inputtedPhoneNumber){
        userObject['phoneNumber'] = inputtedPhoneNumber;
      }
      const vm = this;
      if(tenantVariables.sanitizeEmails && sanitizedEmail && inputtedEmail){
        inputtedEmail = sanitizedEmail;
        base64EncodedEmail = btoa(sanitizedEmail)
        userObject['uid'] = base64EncodedEmail;
      }
      base.update('users/' + base64EncodedEmail, {
        data: userObject,
        context: this,
        then(err){
          if(!err){
            try {
              if(inputtedEmail){
                sessionStorage.setItem('userEmail', inputtedEmail);
              } else {
                sessionStorage.setItem('userEmail', base64EncodedEmail);
              }
              vm.props.setCurrentUser();
              vm.setState({redirect: true})
            } catch(e){
              console.log(e)
              alert(stringConstants.UNABLETOLOGYOUINTEXT)
            }
          } else {
            alert(stringConstants.UNKNOWNERRORTEXT)
          }
        }
      })
      if(base64EncodedEmail) {
        appDatabasePrimaryFunctions.ref('users/' + base64EncodedEmail + '/signUpTime').set(new Date().getTime())
            .catch(function (error) {
              console.log(error)
            })
      }
    }

    handleBirthdayFocus(){
      this.setState({
        birthdayFocused: true
      }, ()=> {
        document.getElementById('year').placeholder ="YYYY"
        document.getElementById('day').placeholder = "DD"
        document.getElementById('month').placeholder = "MM"
      })
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    render() {
      const { redirect } = this.state;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const tenantVariables = this.props.variables || {};
      const htmlParameterEmail = getURLParameter("email");
      const stringConstants = this.props.stringConstants || {};
      const tenantRules = this.state.tenantRules || {};
      const link = tenantRules.rulesAndRegsLink;
      const mlbPrivacyPolicyLink = tenantRules.mlbPrivacyPolicyLink || "https://www.mlb.com/app/ballpark/official-information/privacy-policy";
      const rulesRegsText = tenantRules.rulesAndRegsText || "I agree to the rules and regs";
      const howToPlayLink = tenantRules.howToPlayLink || "";
      let howToPlayText = tenantRules.howToPlayText || "";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "MLB Privacy Policy";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
      const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
      const promotionText = tenantVariables.promotionText || "Check To Opt-In To More Offers From Us";
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
        let urlToGo = "/age_gate"
        let langague = "/"
        let splitWindowLocation = window.location.pathname.split('/') || ""
        if(window.location.pathname.split('/').length > 2){
          langague = splitWindowLocation[2];
          urlToGo += langague;
        }
        return (
            <Redirect to={urlToGo}  />
        )
      }
      if (redirect || this.state.userEmail) {
        let redirectString = "/"
        let langague = ""
        let splitWindowLocation = window.location.pathname.split('/') || ""
        if(window.location.pathname.split('/').length > 2){
          langague = splitWindowLocation[2]
          redirectString += langague
        }
        this.props.setCurrentUser();
        return (
            <Redirect to={redirectString} />
        )
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const rulesPopUpHeader = tenantRules.rulesPopUpHeader || "Rules & Regs";
      const rulesPopUpText = tenantRules.rulesPopUpText || "";
      let showHowToPlayButton = false;
      if(howToPlayText || howToPlayLink){
        showHowToPlayButton = true
      }
      const noEmailCollection = tenantVariables.doNotCollectEmail || isMlbApp;
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <div className="intro-container-home">
                <div className="flex-header-home" style={{marginTop:10, marginBottom: 10}}>
                  <div style={{display: isMlbApp? "block":"none", textAlign: "left", fontFamily: "Oswald"}}>
                    <button className="btn btn-logout" onClick={() => { this.toggleSupport() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Support</button>
                  </div>
                  {isMlbApp &&
                      <div style={{fontFamily: "Oswald"}}>
                        <button className="btn btn-logout" onClick={() => { window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank") }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>MLB TOU</button>
                      </div>
                  }
                  <div style={{display: isMlbApp? "":"none", textAlign:"right", fontFamily: "Oswald"}}>
                    <button className="btn btn-logout" onClick={() => { window.open(mlbPrivacyPolicyLink, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                  </div>
                </div>
                <div className="hero-text-container" style={{paddingTop:0}}>
                  <img src={frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <div className="container-out">
                  <div className="question-box question-form" style={{padding:'15px'}}>
                    <h4 style={{fontFamily: "Oswald", fontWeight: 700, display: htmlParameterEmail || noEmailCollection ? "none":""}}>{stringConstants.ENTEREMAILTEXT}</h4>
                    <form onSubmit={this.handleSubmit} id="user-values-form">
                      <div className="input-group" style={{display: htmlParameterEmail || noEmailCollection ? 'none' : ""}}>
                        <input id="email" name="inputtedEmail" type="email" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDEREMAILTEXT} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectName ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="name" name="inputtedName" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERNAMETEXT} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectPhoneNumber ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="phoneNumber" name="inputtedPhoneNumber" type="tel" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERPHONENUMBERTEXT} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectZipCode ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="zipCode" name="inputtedZipCode" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERZIPCODETEXT} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectBirthday && tenantVariables.formBirthday ? '' : 'none', border: "1px solid #ced4da", borderRadius: "0.25rem", marginTop: 10}}>
                        <input value={this.state.month} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("day").focus()} name="month" id="month" onChange={(e)=> this.handleDateChange(e)} className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} placeholder={stringConstants.PLACEHOLDERBIRTHDAYTEXT} maxLength="2" pattern="\d*"/>
                        {this.state.birthdayFocused &&
                            <>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.day} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("year").focus()} name="day" id="day" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onChange={(e)=> this.handleDateChange(e)} onFocus={(e) => this.handleBirthdayFocus()} maxLength="2" pattern="\d*"/>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.year} name="year" id="year" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} maxLength="4" pattern="\d*" onChange={(e)=> this.handleDateChange(e)}/>
                            </>
                        }
                      </div>
                      <div style={{display:!tenantVariables.noMandatoryTermsAndConditions?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
                          <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{link?window.open(link, '_blank'):this.toggleRules()}}>{rulesRegsText}</span></u></strong></label>
                        </div>
                      </div>
                      <div style={{display:tenantVariables.collectOptIn?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>
                          <input type="checkbox" ref="agree_to_promotion" defaultChecked={!tenantVariables.notDefaultCheckedOptIn && tenantVariables.collectOptIn} style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeToPromotionId">{promotionText}</label>
                        </div>
                      </div>
                      <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>{stringConstants.LETSPLAYTEXT}</strong></button>
                    </form>
                    <button style={{display: showHowToPlayButton?"":"none", backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-rules" onClick={()=>{howToPlayText? this.toggle():window.open(howToPlayLink, '_blank');}}><strong>{stringConstants.HOWTOPLAYTEXT}</strong></button>
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader/>
              <ModalBody style={{textAlign:"center"}}>
                <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                  <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                  <button className="btn btn-play" onClick={() => { this.toggle(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.OKTEXT}</button>
                </div>
              </ModalBody>
              <ModalFooter style={{borderTop:'none'}}/>
            </Modal>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                  {rulesPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Login;
